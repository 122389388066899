import { Component, input } from '@angular/core';
import { ProgressBarComponent } from '../../../../shared/components/charts/progress-bar/progress-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { CoursesService } from '../../courses.service';
import { Plo } from '../../interfaces/aol-course.interface';
import { convertToCSV } from '../../../../shared/util/convert-array-to-csv.util';

@Component({
  selector: 'app-course-plo-achievements',
  standalone: true,
  imports: [ProgressBarComponent, TranslateModule, NgIf],
  templateUrl: './plo-achievements.component.html',
  styleUrl: './plo-achievements.component.scss',
})
export class PloAchievementsComponent {
  constructor(private readonly coursesService: CoursesService) {}

  plos = input.required<Plo[]>();
  ploBenchmark = input.required<number>();
  courseName = input.required<string>();

  selectPlo(plo: Plo) {
    this.coursesService.selectedPlo.set(plo);
  }

  exportAsCSV() {
    const dataToExport = this.plos().map((plo) => {
      return {
        PLOs: plo.name
          .split('')
          .map((char) => (char === ',' ? '' : char))
          .join(''),
        'PLO Benchmark': this.ploBenchmark(),
        'PLO Achievement':
          plo.achievement == -1 ? 'Unmeasured' : plo.achievement.toFixed(2),
      };
    });
    const blob = new Blob([convertToCSV(dataToExport)], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement<'a'>('a');

    a.href = url;
    a.download = this.courseName() + ' - PLO Achievement';
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }
}
